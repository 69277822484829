/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import '~/stylesheets/index.scss';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "http://schema.org",
          "@type": "NailSalon",
          "@id": "https://hermynail.salon",
          "name": "Hermy nail - ハーミーネイル",
          "url": "https://hermynail.salon/",
          "description": "<%= data.config.description %>",
          "logo": "https://hermynail.salon/images/logo.png",
          "image": [
            "https://hermynail.salon/images/json_ld/nail_01.jpg",
            "https://hermynail.salon/images/json_ld/nail_02.jpg",
            "https://hermynail.salon/images/json_ld/nail_03.jpg"
          ],
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "上大岡西3-3-22 プレスティージュ102",
            "addressLocality": "横浜市港南区",
            "addressRegion": "神奈川県",
            "postalCode": "233-0002",
            "addressCountry": "日本"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 35.40333,
            "longitude": 139.5949
          },
          "priceRange": "¥6,000〜",
          "paymentAccepted": "現金",
          "telephone": "045-298-4486",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "10:00",
              "closes": "18:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday"
              ],
              "opens": "10:00",
              "closes": "20:00"
            }
          ]
        }`,
        }}
      />

      <div id="fb-root"></div>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v2.12';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        `,
        }}
      />
      <main>{children}</main>
      <footer>
        <div className="brand">Hermy nail salon</div>
        <p className="copyrights">Copyrights © 2018 Hermy nail salon All rights reserved.</p>
      </footer>
      <div id="netlify-modal"></div>
    </>
  );
};

export default Layout;
